<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('plagiarism')" @new-button-click="createFormShow"
                    @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('plagiarism')" @new-button-click="createFormShow"
                    @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="true" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('student_id')">
                            <b-form-input v-model="datatable.queryParams.filter.student_id" type="number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('course_code')">
                            <b-form-input v-model="datatable.queryParams.filter.course_code">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('level')">
                            <b-form-select v-model="datatable.queryParams.filter.level" :options="levelCreate">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('explanation')">
                            <b-form-input v-model="datatable.queryParams.filter.explanation">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <!-- <CommonModal ref="showModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('city_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('score_information') }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="createFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('city_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('city_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal> -->
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

//Other
import qs from 'qs'
//Service
import PlagiarismService from '@/services/PlagiarismService'
//Page
// import CreateForm from "./CreateForm";
// import UpdateForm from "./UpdateForm";
// import ShowForm from "./ShowForm"

export default {
    name: "PlagiarismListPage",
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        SemestersSelectbox,

        // CreateForm,
        // UpdateForm,
        // ShowForm
    },
    metaInfo() {
        return {
            title: this.$t('plagiarism')
        }
    },
    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    // {
                    //     label: '',
                    //     field: 'buttons',
                    //     html: true,
                    //     sortable: false,
                    //     tdClass: 'p-0 text-center w-40 align-middle',
                    // },
                    {
                        label: this.$t('semester'),
                        field: 'semester',
                        sortable: true,
                        formatFn: (value) => {
                            return value;
                        }
                    },
                    {
                        label: this.$t('name_surname'),
                        field: 'name',
                        sortable: true,
                        formatFn: (value, row) => {
                            return `${row.name} ${row.surname}`
                        }
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        sortable: false,
                    },
                    {
                        label: this.$t('course_code'),
                        field: 'course_code',
                        sortable: false,
                    },
                    {
                        label: this.$t('course_name'),
                        field: 'course_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('faculty_name'),
                        field: 'faculty_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('faculty_code'),
                        field: 'faculty_code',
                        sortable: false,
                    },
                    {
                        label: this.$t('level'),
                        field: 'level_name',
                        sortable: false,
                    },

                    {
                        label: this.$t('explanation'),
                        field: 'explanation',
                        sortable: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    computed: {
        levelCreate() {
            let data = []
            for (let index = 1; index < 6; index++) {
                data.push({text: `${this.$t('level')}: ${index}`, value: index})
            }
            return data
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return PlagiarismService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData(id) {
            this.formProcess = "update";
            this.formId = id;
            PlagiarismService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.$refs.updateFormModal.$refs.commonModal.show();
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        delete() {
            this.deleteModal(() => {

            });
        },
        async createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
        exportExcel() {
			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			return PlagiarismService.exportExcel(config)
				.then((response) => {
					this._downloadFile(response, 'plagiarism.xlsx')
				})
				.catch(e => {
				});
		},
    }
};
</script>

